/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const $ = require("jquery")

export const onRouteUpdate = () => {

    $(function(){
        $('.org-chart-frame').scrollLeft(150);

            // グローバルヘッダー操作
        $('#global-navigation .menu-list-btn').click(function() {
            if ($(this).hasClass('active') === true) {
                $(this).removeClass('active');
                $('#global-navigation .navigation-frame').removeClass('active');
            } else {
                $(this).addClass('active');
                $('#global-navigation .navigation-frame').addClass('active');
            }
        });
        $('#global-navigation .navigation-frame').click(function() {
            $('#global-navigation .menu-list-btn').removeClass('active');
            $('#global-navigation .navigation-frame').removeClass('active');
        });

        if (window.location.pathname.match(/wholesale-price/)) {
                $.ajax({
                    url: 'https://script.google.com/macros/s/AKfycbxVpW9plX86hab-ZzWyMyOS_j3pPy8UpOBINLcokgcq1JjOM0A/exec',
                    dataType: 'json',
                    success: function( json ) {
                        let result = analyzeData(json);
                        if (result.length > 0) {
                            viewData(result);
                            $('.wholesale-spinner-frame').css({'display':'none'});
                            $('.wholesale-date').css({'display':'block'});
                            $('.wholesale-prev-amount').css({'display':'flex'});
                            $('.wholesale-daily-price').css({'display':'block'});
                        }
                    },
                    error: function( data ) {
                      $('.progress-spinner').css({'display':'none'});
                      $('.error-message').empty();
                      $('.error-message').html('エラーが発生しました。ページをリロードしてください。');
                      $('.error-message').css({'display':'block'});
                    }
                  });
            }
    });

    const analyzeData = (json) => {
        var result = [];
        var prevObj;
        json.forEach(( lastObj ) => {
            let keyName = Object.keys(lastObj);
            if (typeof prevObj !== 'undefined') {
                var lastDate = new Date(lastObj[keyName[0]]);
                // lastDate.setTime(lastDate.getTime() - 1000*60*60*9);// JSTに変換
                let prevDate = new Date(prevObj[keyName[0]]);
                // prevDate.setTime(prevDate.getTime() - 1000*60*60*9);// JSTに変換
                var isSameDay = (lastDate.getDate() === prevDate.getDate() && lastDate.getMonth() === prevDate.getMonth() && lastDate.getFullYear() === prevDate.getFullYear());
                if (isSameDay === false) {
                    result.push(prevObj);
                    prevObj = lastObj;
                    return;
                } else {
                    keyName.forEach((k) => {                
                        if (lastObj[k] === '') {
                            lastObj[k] = prevObj[k];
                        } else if (lastObj[k] === '-') {
                            lastObj[k] = '';
                        }
                    })
                    prevObj = lastObj;
                }
            } else {
                prevObj = lastObj;
            }
        });
        result.push(prevObj);
    //     console.dir('result:' + result);
        return result;
    }
    
    const getNextBizDate = (da) => {
        
        let result = holidays.filter(function( item ) {
            let ho = new Date(item);
            let holiday = String(ho.getFullYear()) + String(ho.getMonth()+1) + String(ho.getDate()); // 休市日
            let date = String(da.getFullYear()) + String(da.getMonth()+1) + String(da.getDate()); // 営業候補日
                    
            return holiday === date;
        });
        
        if (result.length) { // 営業候補日が休市日に当たったらその翌日を確認
            da.setDate( da.getDate() + 1 );
            result = getNextBizDate(da);
            return result;
        } else {
            return da;
        }
    }
    
    const viewData = (data) => {
        let lastData = data[data.length - 1];
        // let prevData = data[data.length - 2];
        
        let keyName = Object.keys(lastData);
        var prevDate = new Date(lastData[keyName[0]]); // 最終入力日は「前営業日分」
    //     prevDate = new Date("2021-01-09");
        
        let dateObj = new Date(prevDate);
        dateObj.setDate( prevDate.getDate() + 1 ); // 「本日分」は最終入力日の翌営業日
        let today = getNextBizDate(dateObj); // 休市日テーブルから翌営業日を判定
    
        let elem = `[${today.getFullYear()}年${today.getMonth()+1}月${today.getDate()}日3時現在]`;
        
        $('.wholesale-date').html(elem);
    
        $('.wholesale-prev-amount .sengyo .data').html(lastData[keyName[1]]);
        $('.wholesale-prev-amount .tokusyu .data').html(lastData[keyName[2]]);
        $('.wholesale-prev-amount .maguro .data').html(lastData[keyName[3]]);
        $('.wholesale-prev-amount .reito .data').html(lastData[keyName[4]]);
        $('.wholesale-prev-amount .kakohin .data').html(lastData[keyName[5]]);
        let total = lastData[keyName[1]] + lastData[keyName[2]] + lastData[keyName[3]] + lastData[keyName[4]] + lastData[keyName[5]];
        $('.wholesale-prev-amount .total .data').html(total);
    
        elem = '（' + (today.getMonth()+1) + '月' + today.getDate() + '日）';
        $('.heading .lastDate').html(elem);
    
        elem = '（' + (prevDate.getMonth()+1) + '月' + prevDate.getDate() + '日）';
        $('.heading .prevDate').html(elem);
    
        var itemData = $('.itemData').clone(true);
        $('.itemData').remove();
    
        for (var i = 1 ; i <= 222 ; i=i+9 ) {
            elem = $(itemData).clone(true);
    
            const regex = /-(.*?)　/g;
            const matched = regex.exec(keyName[i+6]);
            if (!matched) {
                return -1;
            }
            $(elem).find('.item').html(matched[1]);
            $(elem).find('.santi').html(lastData[keyName[i+6]]);
            $(elem).find('.amount').html(lastData[keyName[i+7]].toLocaleString());
            $(elem).find('.prev-santi').html(lastData[keyName[i+8]]);
            if (lastData[keyName[i+9]] === 'せり') {
                $(elem).find('.seri-amount').html(lastData[keyName[i+10]].toLocaleString());
                $(elem).find('.seri-unit').html(lastData[keyName[i+11]]);
                $(elem).find('.seri-high').html(lastData[keyName[i+12]].toLocaleString());
                $(elem).find('.seri-middle').html(lastData[keyName[i+13]].toLocaleString());
                $(elem).find('.seri-low').html(lastData[keyName[i+14]].toLocaleString());
            } else if (lastData[keyName[i+9]] === '相対') {
                $(elem).find('.soutai-amount').html(lastData[keyName[i+10]].toLocaleString());
                $(elem).find('.soutai-unit').html(lastData[keyName[i+11]]);
                $(elem).find('.soutai-high').html(lastData[keyName[i+12]].toLocaleString());
                $(elem).find('.soutai-middle').html(lastData[keyName[i+13]].toLocaleString());
                $(elem).find('.soutai-low').html(lastData[keyName[i+14]].toLocaleString());
            }
            $('.wholesale-daily-price .datas').append(elem);
        }
    
    }
    
    const holidays = [
        "2025-01-01",
        "2025-01-02",
        "2025-01-03",
        "2025-01-04",
        "2025-01-08",
        "2025-01-12",
        "2025-01-13",
        "2025-01-19",
        "2025-01-22",
        "2025-01-26",
        "2025-01-29",
        "2025-02-02",
        "2025-02-05",
        "2025-02-09",
        "2025-02-11",
        "2025-02-16",
        "2025-02-19",
        "2025-02-23",
        "2025-02-24",
        "2025-03-02",
        "2025-03-05",
        "2025-03-09",
        "2025-03-12",
        "2025-03-16",
        "2025-03-19",
        "2025-03-20",
        "2025-03-23",
        "2025-03-26",
        "2025-03-30",
        "2025-04-02",
        "2025-04-06",
        "2025-04-09",
        "2025-04-13",
        "2025-04-16",
        "2025-04-20",
        "2025-04-23",
        "2025-04-27",
        "2025-04-29",
        "2025-05-04",
        "2025-05-05",
        "2025-05-06",
        "2025-05-11",
        "2025-05-14",
        "2025-05-18",
        "2025-05-21",
        "2025-05-25",
        "2025-05-28",
        "2025-06-01",
        "2025-06-04",
        "2025-06-08",
        "2025-06-11",
        "2025-06-15",
        "2025-06-18",
        "2025-06-22",
        "2025-06-25",
        "2025-06-29"
    ];
}
